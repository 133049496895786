.cmpt-container {
    margin-top: var(--space)
}


.sb-input {
    border:none;
    width:350px;
    background-color:white
}

.sb-input:focus {
    outline:none;
}

.sb-container {
    border:2px solid #949494;
    padding:5px 0px 5px 0px;
    width:400px;
}

.cmpt-black-border {
    border:1px solid black
}



.cmpt-links {
    text-decoration: none
}

.cmpt-color-blue {
    color:blue
}

.cmpt-sidebar-menu-item {
    cursor:pointer;
    list-style-type: none;
    padding:15px 5px 15px 35px
}
.cmpt-sidebar-menu-item span {
    font-size: 0.8em;
    /*line-height:2.8em;*/
}
.cmpt-sidebar-menu-item:hover {
    /* background-color:rgb(16,40,66) */
}
.selected {
    color: var(--primary);
    border: 2px solid var(--primary);
}

.menu-item-span {
    color: #111;
}
.cmpt-off-white {
    color:#ccc;
}
.cmpt-sub-sidebar-menu-item {
    /*cursor:pointer;*/
    list-style-type: none;
    padding:15px 20px 15px 40px;
    /* background:rgb(16,40,66); */
    /*padding-left:20px;*/
}
.cmpt-sub-sidebar-menu-item span {
    font-size: 0.8em;
    /*line-height:2.8em;*/
}
.cmpt-sub-sidebar-menu-item:hover {
    /* background-color:rgb(0,33,68) */
}

.cmpt-display-none {
    display:none;
}

.cmpt-ul {
    padding: 0px
}

.cmpt-selected-list-open {
     -webkit-transform: rotate(-90deg);
     -webkit-transition: 0.5s;
  /*-webkit-animation: slow 1.5s linear;*/
  /*-webkit-animation-iteration-count: 1;*/
  /*-webkit-animation-delay:2s*/
  
}

.cmpt-selected-list-close {
     -webkit-transform: rotate(0deg);
     -webkit-transition: 0.5s;
  /*-webkit-animation: slow 1.5s linear;*/
  /*-webkit-animation-iteration-count: 1;*/
  /*-webkit-animation-delay:2s*/
  
}

.space-item {
    display: flex;
    justify-content: space-between;
}



