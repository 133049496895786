.org-inpup-container {
  min-width: 400px;
  max-width: 500px;
}

.spin-contain {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.set-btn {
  border: none;
  color: blue;
  background-color: white;
}
