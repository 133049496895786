.sbh-container {
    height: 100px;
    width: 255px;
    /* box-shadow: 0 0px 8px 0 rgba(100, 100, 100, 0.2); */
    display: flex; 
    flex-direction: row;
    justify-content: center;
    position: absolute;
    z-index: 1;
    background-color: rgb(254, 254, 254);
    
    box-shadow: 0 4px 2px -2px gray;
}
.sbh-topImgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sbh-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sbh-content img {
    width: 125px;
    /* height:80px; */
}

.sbh-logo {
    height: 30px;
    width: 25px;
}

.sbh-bottomText {
    font-size: var(--h3);
    text-transform: capitalize;
}