.lch-container {
    height: 70px;
    width: 82%;
    /* box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    /* z-index: 1; */
    margin-left: 255px;
}

.lch-content {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
}
.lch-leftIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    transition: background-color .5s;
    /* transition: 0.3s; */
}

.lch-leftTextContainer {
    text-transform: capitalize;
    font-weight: 500;
    /* transition: 0.3s; */
}

.lch-leftIconContainer:hover {
    background-color: #eee;
}

.lch-leftIconContainer-icon {
    font-size: 20px;
    padding: 0 20px;
}

.lch-avatarImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.lch-headerEnd {
    /* position: absolute;
    right: 550px;
    top: 170px; */
    display: flex;
    justify-content: flex-end;
    z-index: 1;
}

.lch-left {
    display: flex;
    justify-content: space-between;
}

.lch-contract-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid green; */
    margin: 0 var(--space);
    height: 100%;
}

.lch-contract-header div {
    padding: 0 var(--tinySpace);
}