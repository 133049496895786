body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --blue: #1e90ff;
  --white: #fff;
  --primary: rgb(37, 147, 252);
  --green: rgb(0, 169, 158);
  --illustration: rgb(240, 242, 245);
  --verticalMargin: 20px;
  --space: 20px;
  --smallSpace: 10px;
  --tinySpace: 5px;
  --h1: 24px;
  --h2: 20px;
  --h3: 18px;
  --h4: 18px;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --customiseFontColor: rgb(103, 103, 103);
}

.space-between {
  display: flex;
  flex-direction: row;
}
